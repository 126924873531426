.send-tx-form {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 20px;
  align-items: center;

  h3.ant-typography {
    color: white;
    opacity: 0.8;
    margin-bottom: 10px !important;;
  }

  h4.ant-typography {
    color: white;
    opacity: 0.8;
    margin-top: 0 !important;
  }

  &__error {
    color: rgba(102, 170, 238, 0.91);
    font-size: 18px;
    line-height: 20px;
  }

  @media (max-width: 500px) {
    margin-top: 40px;
    text-align: center;
  }
}

.send-tx-wrapper {
  color: white;
  display: flex;
  flex-direction: column;

  > div:nth-child(2) {
    width: 100%;

    span {
      word-break: break-word;
    }
  }

  > *:nth-child(3) {
    margin: 10px auto;
  }

}

.send-tx-input-wrapper {
  width: 350px;
  margin-bottom: 8px;
}


.send-tx-input-wrapper-error {
  color: red;
  visibility: hidden;

  &_show {
    visibility: visible;
  }
}

.buy-buttons {
  display: flex;
  justify-content: center;
  gap: 15px;
}
