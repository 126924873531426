.ant-modal-wrap.sign-wrapper {
  overflow-x: hidden;
}

.main-menu {
  padding-bottom: 0;

  .ant-modal-header {
    background: #082148;
    border: none;
    border-top-left-radius: 32px;
    border-top-right-radius: 32px;
    padding: 40px 40px 30px 40px;
  }

  .ant-modal-body {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 50px 40px;
  }

  .ant-modal-close {
    color: #FDFFFD;
    top: 20px;
    right: 20px;

    &-x {
      width: 24px;
      height: 24px;
      line-height: 24px;
      font-size: 20px;
    }
  }

  .ant-modal-title {
    font-family: Montserrat, sans-serif;
    font-size: 29px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0;
    color: #FDFFFD;
  }

  .ant-modal-content {
    background: none;
    box-shadow: none;
  }

  &-button {
    display: none;
  }
}