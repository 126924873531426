.customization-slider {
  display: flex;
  align-items: center;
  margin-left: 0 !important;
  max-width: 737px;
  padding-right: 98px !important;
  width: 100%;

  &::after {
    content: "";
    width: 75px;
    height: 100%;
    position: absolute;
    z-index: 2;
    right: 0;
    top: 0;
    background: #021228;
  }

  .swiper-wrapper {
    padding-bottom: 15px;
  }

  .swiper-slide {
    width: 60px;
    height: 60px;
    background: #ebebeb;
    cursor: pointer;
    border-radius: 5px;
    position: relative;

    &.active {
      background: #4dda36;
    }

    img {
      max-width: 70px;
      position: absolute;
      left: 0;
      top: 0;
    }

    .heads {
      left: -6px;
      top: 2px;
    }

    .tShirts {
      left: -5px;
      top: -22px;
    }

    .shorts {
      left: -6px;
      top: -38px;
    }

    .boots {
      left: -6px;
      top: -55px;
    }
  }

  &__button-prev,
  &__button-next {
    font-size: 0;
    left: auto !important;
    width: 34px !important;
    height: 60px !important;
    background: #fdfffd;
    border-radius: 5px;
    top: 22px !important;
    margin: 0;
    opacity: 1;

    &::after {
      content: "";
      width: 24px;
      height: 24px;
      font-size: 0 !important;
    }

    &.swiper-button-prev.swiper-button-disabled, &.swiper-button-next.swiper-button-disabled {
      opacity: 1;

      &:after {
        opacity: 0.3;
      }
    }
  }

  &__button-prev {
    right: 54px !important;

    &::after {
      background: url("./../../../assets/custom-arrow-left.svg") center / cover no-repeat;
    }

    &:hover {
      background: #082148;

      &::after {
        background: url("./../../../assets/arrow-white-left.svg") center / cover no-repeat;
      }
    }
  }

  &__button-next {
    right: 0;

    &::after {
      background: url("./../../../assets/custom-arrow-right.svg") center / cover no-repeat;
    }

    &:hover {
      background: #082148;

      &::after {
        background: url("./../../../assets/arrow-white-right.svg") center / cover no-repeat;
      }
    }
  }

  .swiper-scrollbar {
    bottom: 0;
    background: rgba(#fdfffd, 0.2);
    border-radius: 4px;
    left: 0;
    width: 100%;

    &-drag {
      background: rgba(#fdfffd, 0.3);
      border-radius: 4px;
    }
  }
}
