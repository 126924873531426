.dapp-title {
  display: none;
  gap: 8px;
  @media (max-width: 500px) {
    display: none;
    gap: 0;
  }

  &__text {
    font-size: 12px;
    line-height: 34px;
    color: rgba(102, 170, 238, 0.91);
    font-weight: bold;
  }

  &__badge {
    height: fit-content;
    padding: 0 8px;
    background-color: #ff4d4f;
    border-radius: 10px;
    margin-top: 8px;

    font-size: 12px;
    line-height: 20px;
    color: white;
  }
}
