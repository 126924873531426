.app {
  position: relative;
  overflow: hidden;

  main {
    padding: 28px 40px;
  }

  @media (max-width: 500px) {
    main {
      padding: 20px;
    }
  }
}

.custom {
  margin-top: 60px;

  &__title {
    font-size: 32px;
    text-align: center;
    font-weight: 800;
    line-height: normal;
    color: #fdfffd;
    font-family: Monserat, sans-serif;
  }

  &__inner {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin: 0 auto 60px auto;
    max-width: 1474px;
    padding: 0 15px;
    position: relative;
  }

  &__controller {
    max-width: 941px;
    width: 100%;
    display: flex;
  }

  &__variants {
    display: none;
  }

  &__variant {
    text-align: center;
  }

  &__buttons {
    text-align: center;
  }

  &__subtitle {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
    margin-bottom: 44px;
  }

  &__text {
    margin-top: 30px;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #000000;
  }

  &__subject {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;

    .custom__subtitle {
      opacity: 0;
      position: absolute;
    }
  }

  &__subtitle {
    display: none;
  }

  &__platform {
    transform: translateY(-40%);
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      object-fit: cover;
      max-width: 420px;
      width: 100%;
      height: 100%;
    }
  }

  &__colors {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 5;
    width: 19.8px;
    height: 306.2px;
  }

  &__color {
    width: 19.8px;
    height: 19.8px;
    cursor: pointer;
    border-radius: 1.32px;
    position: relative;

    &.active {
      &::after {
        content: "";
        position: absolute;
        width: 6px;
        height: 6px;
        background: #4dda36;
        border-radius: 50%;
        top: 50%;
        left: -12px;
        transform: translateY(-50%);
      }
    }

    &_1 {
      .custom__dermo {
        background: #fcd09f;
      }
    }

    &_2 {
      .custom__dermo {
        background: #ffcc99;
      }
    }

    &_3 {
      .custom__dermo {
        background: #ffbe92;
      }
    }

    &_4 {
      .custom__dermo {
        background: #fcc0a2;
      }
    }

    &_5 {
      .custom__dermo {
        background: #ffaf77;
      }
    }

    &_6 {
      .custom__dermo {
        background: #f7b86d;
      }
    }

    &_7 {
      .custom__dermo {
        background: #d87e41;
      }
    }

    &_8 {
      .custom__dermo {
        background: #9c755a;
      }
    }

    &_9 {
      .custom__dermo {
        background: #6d5550;
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &__content-part {
    display: flex;
    align-items: center;
    gap: 30px;
  }

  &__parts {
    display: flex;
    flex-direction: column;
    gap: 35px;
  }

  &__part {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 60px;
    height: 60px;
    color: #082148;
  }

  &__dermo {
    width: 100%;
    height: 100%;
    border-radius: 2px;
  }

  .custom-human {
    position: relative;
    width: 288.34px;
    height: 384.454px;
    margin: 0 auto;

    img {
      position: absolute;
      max-width: 100%;
      max-height: 477px;
    }

    &__head {
      z-index: 4;
    }

    &__left-shoulder {
      z-index: 3;
    }

    &__body {
      z-index: 2;
    }

    &__tshirt {
      display: none;
      z-index: 2;
    }

    &__right-hand {
      z-index: 4;
    }

    &__left-hand {
      z-index: 4;
    }

    &__underpants {
      z-index: 3;
    }

    &__legs {
      z-index: 1;
    }

    &__boots {
      z-index: 2;
    }
  }

  .update-description {
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: rgb(253, 255, 253);
    text-align: center;
    margin-top: 30px;
  }

  .button {
    margin-top: 43px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    &__inner {
      max-width: 640px;
    }
  }

  &__gets {
    display: flex;
    align-items: center;
    max-width: 427px;
    margin: 0 auto;
    gap: 40px;
    justify-content: center;
  }

  &__soon {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #000000;
  }

  &__button {
    max-width: 340px;
    width: 100%;
    background: #a3f296;
    border-radius: 14px;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    cursor: pointer;
    color: #ffffff;
    text-align: center;
  }

  &__character {
    display: flex;
    align-items: center;
    max-width: 458px;
  }

  &-choose {
    height: 234px;
    width: 60px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow-y: auto;

    padding-right: 5px;
    &-none {
      display: none;
    }
    &__item {
      padding: 5px 10px 10px 10px;
      border-radius: 5px;
      background: #ebebeb;
      width: 100%;
      min-height: 71.302px;
      max-height: 71.302px;
      height: 100%;
      cursor: pointer;
      opacity: 0.4;

      &-active {
        opacity: 1;
        cursor: default;
      }
    }

    &__image {
      width: 100%;
      height: 100%;

      img {
        object-fit: cover;
        max-width: 50px;
        width: 100%;
        height: 100%;
      }
    }
    @media (min-width: 900px) {
      &::-webkit-scrollbar {
        width: 5px;
      }
      &::-webkit-scrollbar-track {
        background: rgba(#fff, 0.5);
        border-radius: 10px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: blue;    /* цвет бегунка */
        border-radius: 20px;       /* округлось бегунка */
        border: 3px solid #fff;  /* отступ вокруг бегунка */
      }
    }
  }
  


  @media (max-width: 1330px) {
    &__inner {
      align-items: center;
      flex-direction: column-reverse;
    }
  }

  @media (max-width: 900px) {
    .update-button {
      width: 200px;
      padding: 5px 20px;
    }
    &__controller {
      flex-direction: column;
      gap: 15px;
    }
    &__parts {
      flex-direction: row;
      justify-content: center;
      gap: 10px;
    }
    &__part {
      justify-content: center;
      width: 76.25px;
      height: 47px;
      border-radius: 5px 5px 0 0;
      background: rgba(255, 255, 255, 0.20);
      position: relative;
      &::after {
        content: '';
        width: 100%;
        height: 3px;
        position: absolute;
        left: 0;
        bottom: 0;
        background: #E3E3E3;
      }
      img {
        width: 24px;
        height: 24px;
      }
      &.active {
        background: rgba(77, 218, 54, 0.30);
        &:after {
          background: #4DDA36;
        }
      }
    }
    &__content-part {
      display: none;
      &.visible {
        display: flex;
      }
    }
  }
  @media (max-width: 880px) {

    &__controller {
      .custom__subtitle {
        display: none !important;
      }
    }
    .head-slider {
      padding-right: 0;
      padding-left: 10px;
    }
    .swiper-scrollbar {
      bottom: 0;
      background: #f4f4f4;
      border-radius: 4px;

      &-drag {
        background: #d9d9d9;
        border-radius: 4px;
      }
    }
    .head-slider__button-prev,
    .head-slider__button-next,
    .head-slider::after {
      display: none;
    }
    &__subject {
      .custom__subtitle {
        opacity: 1;
        position: relative;
      }
    }
    &__subtitle {
      margin-top: 26px;
      display: block;
      text-align: center;
    }
    &__part {
      //display: none !important;
    }
    &__text {
      display: none !important;
    }
    &__content-part {
      flex-direction: column;
    }
    &__variants {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      margin-bottom: 18px;
    }

    &__variant {
      padding: 10px;
      min-width: 75px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: #000000;
      border-bottom: 3px solid #e3e3e3;
      border-radius: 4px 4px 0 0;

      &.active {
        background: #ebebeb;
        border-bottom: 3px solid #4dda36;
      }
    }
  }
  @media (max-width: 768px) {
    &__title {
      padding: 0 15px;
    }
    &__subject {
      position: relative;
      padding: 0 15px;
      width: calc(100% - 30px);
      margin: 0 auto;
    }
    &__inner {
      padding: 0;
    }
    &__controller {
      width: 100%;
    }
    .head-slider {
      max-width: 100%;
    }
  }
  @media (max-width: 500px) {
    &__gets {
      max-width: none;
    }
    &__subtitle {
      margin-top: 0;
    }
    .update-button {
      max-width: none;
      width: 100%;
    }
    &__inner {
      margin-bottom: 30px;
    }
    &__button {
      max-width: 248px;
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
    }
    .custom__human {
      width: 220.58px;
      height: 294.106px;
    }
    &__colors {
      transform: translateY(-30%);
    }
    .swiper-scrollbar {
      max-width: calc(100% - 30px);
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
