.auth-buttons {
  display: flex;
  align-items: center;
  gap: 15px;
}
.ant-modal.main-menu {
  padding-bottom: 0;
}

.auth-button {
  display: flex;
  flex-direction: column;
  width: fit-content;
  gap: 5px;
  @media (max-width: 540px) {
    width: 100%;
  }
}

.ant-dropdown {
  .ant-dropdown-menu {
    background-color: #082148;
    border-radius: 14px;
    text-align: center;

    &-title-content {
      font-weight: 600;
      font-size: 20px;
      line-height: 30px;
      color: #fdfffd;
    }
  }

  .ant-dropdown-menu-item:hover {
    border-radius: 14px;
    background-color: #0e2956;
    height: 100%;
  }

  .ant-dropdown-menu-item,
  .ant-dropdown-menu:hover {
    border-radius: 14px;
  }

  .ant-dropdown-menu-item-active {
    background-color: rgba(#0e2956, 1.1);
  }
}
